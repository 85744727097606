.footer {
    margin: auto;
    width: 98%;
}

.dev {
    display: flex;
    justify-content: start;
  }
  
  .logoDev {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 200px;
    margin-bottom: 5px;
  }
  
  .logoDev p {
    text-align: center;
    font-size: 13px;
    width: 100%;
  }
  
  .logoDev img {
    width: 80px;
    transition: .5s ease;
    margin-top: -20px;
  
    &:hover {
      transform: scale(1.1);
    }
  }
  
  .rightsText {
    display: flex;
    justify-content: center;
    align-items: end;
    margin-bottom: 15px;
  }
  
  .rightsText h6 {
    color: #fff;
  }
  
  .rightsText a {
    color: #fff;
    text-decoration: none;
    transition: .3s ease;
  
    &:hover {
      color: #445de7;
    }
  }
  
  @media (max-width: 575px) {
    .dev {
      justify-content: center;
    }
  }