.background {
  background-color: #0d5f9f;
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  background: rgba(255, 255, 255, 0.397);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
}

@media (max-width: 550px) {
  .login {
    width: 100%;
  }
}
