.missionaryPicture {
    border: 10px solid transparent;
    border-radius: 15px;
    background: linear-gradient(165deg, rgba(9, 126, 121, 1) 0%, 33%, rgba(231, 24, 46, 1) 33%, 66%, rgba(255, 224, 29, 1) 66%);
    background-clip: padding-box;
    padding: 15px;
    border-radius: 50px;
    width: 70%;
}

.bandeiraRs {
    width: 30%;
    border: 5px solid #00000083;
    border-radius: 15px;
    float: left;
    margin-right: 15px;
    margin-bottom: 5px;
}